import * as React from "react";
import { Link, navigate } from "gatsby";
import { useTranslation } from 'react-i18next';

// Custom imports
import gatsbyConfig from '../../gatsby-config';
import MainLayout from '../components/main-layout';
import img404 from '../../static/img/404.png';
import '../styles/main.scss';

const NotFoundPage = (props) => {
    const { pageContext } = props;
    const { t } = useTranslation();

    return (
        <MainLayout
            pageContext={pageContext}
        >
            <div className="container" style={{ paddingTop: gatsbyConfig.siteMetadata.navHeaderHeight }}>
                <div className="row" style={styles.pageStyles}>
                        <div className="col-12 col-md-6 mb40">
                            <h1 style={styles.headingStyles}>{t('page404.pageNotFound')}</h1>
                            <p style={styles.subtitles}>{t('page404.message1')}</p>
                            <p style={styles.subtitles}>{t('page404.message2')}</p>
                            <p style={styles.subtitles}>{t('page404.message3')}</p>
                            <div style={styles.buttonsWrapper}>
                                <Link style={styles.styleLink1} to="/" className="btn btn-sm btn-primary mb15">{t('page404.link1')} </Link>
                                <button style={styles.styleLink2} onClick={() => navigate(-1)} className="btn btn-sm">{t('page404.link2')}</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb40">
                            <img src={img404} alt={t('page404.pageNotFound')} style={{ maxWidth: 350 }} />
                        </div>
                    </div>
            </div>
        </MainLayout>
    );
}

// styles
const styles = {
    subtitles: {
        marginBottom: "3px",
        color: "#616161",
        fontSize: "18px"
    },
    buttonsWrapper: {
        marginTop: "10px"
    },
    pageStyles: {
        color: "#232129",
        // paddingRight: "96px",
        // paddingLeft: "70px",
        paddingTop: "150px",
        paddingBottom: "200px",
        display: "flex",
        alignItems: "center"
    },
    headingStyles: {
        marginBottom: 10
    },
    styleLink1: {
        marginRight: '35px',
        marginTop: '15px',
        fontSize: '20px',
        textDecoration: 'none',
        cursor: 'pointer',
        letterSpacing: '0.65px',
        fontWeight: 500,
        borderColor: 'transparent',
        '&:hover': {
            color: 'white',
            borderColor: 'transparent',
            textDecoration: 'none',
        },
        color: 'white'
    },
    styleLink2: {
        fontSize: '20px',
        cursor: 'pointer',
        letterSpacing: '0.65px',
        fontWeight: 500,
        borderColor: 'transparent',
        '&:hover': {
            color: 'grey',
            borderColor: 'transparent',
            textDecoration: 'none',
        },
        color: 'black'
    }
};

export default NotFoundPage;
